<template>
<div>
  <div id="main" style="width: 100%;height:600px;margin-top: 50px;font-size: 16px"></div>
</div>
</template>

<script>
import clientUtils from "../../js/clientUtils";
export default {
  name: "Phonepic",
  data(){
    return {
      option: {
        dataZoom: [
          {
            show: true,
            height: 5,
            xAxisIndex: [0],
            bottom: 20,
            showDetail: false,
            showDataShadow: false,
            borderColor: "transparent",
            textStyle: {
              fontSize: 0
            },
            endValue: 4,//从0开始的相当于5个
            backgroundColor: "rgba(0,0,0,0)",
            borderWidth: 0,
            handleSize: "0%",
            handleStyle: {
              color: "#d3dee5"
            }
          }
        ],
        title: {},
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },

        legend: {
          type: 'scroll',            // 设置图例翻页
          icon: 'rect',
          itemWidth: 12,             // 图例图形宽度
          itemHeight: 10,
          orient: 'horizontal',     // 图例横向排布
          left: 15,
          top: 20,
          selectedMode: 'single',
        },
        grid: {
          left: '2%',
          right: '4%',
          bottom: '1%',
          containLabel: true
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            interval: 0,
            rotate: 270,
          },
          data: [],
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '实供面积', type: 'line', stack: '总量', data: []
          },
          {
            name: '一网供暖', type: 'line', stack: '总量', data: []
          },
          {
            name: '联网面积', type: 'line', stack: '总量', data: []
          },
          {
            name: '一网回温', type: 'line', stack: '总量', data: []
          },
          {
            name: '一网供压', type: 'line', stack: '总量', data: []
          },
          {
            name: '一网回压', type: 'line', stack: '总量', data: []
          },
          {
            name: '二网供暖', type: 'line', stack: '总量', data: []
          },
          {
            name: '二网回温', type: 'line', stack: '总量', data: []
          },
          {
            name: '二网供压', type: 'line', stack: '总量', data: []
          },
          {
            name: '二网回压', type: 'line', stack: '总量', data: []
          },
          {
            name: '温度目标设定', type: 'line', stack: '总量', data: []
          },
          {
            name: '二网压力上限', type: 'line', stack: '总量', data: []
          },
          {
            name: '二网压力设定上限', type: 'line', stack: '总量', data: []
          },
          {
            name: '二网压力设定下限', type: 'line', stack: '总量', data: []
          },
          {
            name: '二网压力下限', type: 'line', stack: '总量', data: []
          },
          {
            name: '一阀给定', type: 'line', stack: '总量', data: []
          },
          {
            name: '一阀反馈', type: 'line', stack: '总量', data: []
          },
          {
            name: '二阀给定', type: 'line', stack: '总量', data: []
          },
          {
            name: '二阀反馈', type: 'line', stack: '总量', data: []
          },
          {
            name: '水箱水位', type: 'line', stack: '总量', data: []
          },
          {
            name: '水箱水位下限', type: 'line', stack: '总量', data: []
          },
          {
            name: '循环泵启动', type: 'line', stack: '总量', data: []
          },
          {
            name: '循环泵给定', type: 'line', stack: '总量', data: []
          },
          {
            name: '循环泵频率', type: 'line', stack: '总量', data: []
          },
          {
            name: '循环泵故障代码', type: 'line', stack: '总量', data: []
          },
          {
            name: '补水泵启动', type: 'line', stack: '总量', data: []
          },
          {
            name: '补水泵给定', type: 'line', stack: '总量', data: []
          },
          {
            name: '补水泵频率', type: 'line', stack: '总量', data: []
          },
          {
            name: '补水泵故障代码', type: 'line', stack: '总量', data: []
          },
          {
            name: '一网流量设定', type: 'line', stack: '总量', data: []
          },
          {
            name: '一网流量单耗', type: 'line', stack: '总量', data: []
          },
          {
            name: '一网流量', type: 'line', stack: '总量', data: []
          },
          {
            name: '一网流量累计', type: 'line', stack: '总量', data: []
          },
          {
            name: '一网热量', type: 'line', stack: '总量', data: []
          },
          {
            name: '一网热量累计', type: 'line', stack: '总量', data: []
          },
          {
            name: '预测热量', type: 'line', stack: '总量', data: []
          },
          {
            name: '补水流量', type: 'line', stack: '总量', data: []
          },
          {
            name: '补水流量累计', type: 'line', stack: '总量', data: []
          },
          {
            name: '二网流量', type: 'line', stack: '总量', data: []
          },
          {
            name: '二网流量累计', type: 'line', stack: '总量', data: []
          },
          {
            name: '瞬时电量', type: 'line', stack: '总量', data: []
          },
          {
            name: '电量累计', type: 'line', stack: '总量', data: []
          },
          {
            name: '热负荷', type: 'line', stack: '总量', data: []
          },
          {
            name: '一网流量、年初始', type: 'line', stack: '总量', data: []
          },
          {
            name: '一网热量、年初始', type: 'line', stack: '总量', data: []
          },
          {
            name: '补水流量、年初始', type: 'line', stack: '总量', data: []
          },
          {
            name: '电量年初始', type: 'line', stack: '总量', data: []
          },
          {
            name: '平均室温', type: 'line', stack: '总量', data: []
          },
          {
            name: '地积水报警', type: 'line', stack: '总量', data: []
          },
          {
            name: '调试状态开启', type: 'line', stack: '总量', data: []
          }
        ]
      }
    }
    },
  mounted() {
    const that = this
    clientUtils.get("/system/networkData/findAll",function (res) {
      if (res.data!=null){
        console.log(res.data)
        for (let i = 0; i < res.data.data.length; i++) {
          console.log(res.data.data[i].createDate)
          console.log(res.data.data[i].supplyArea)
          that.option.series[0].data.push(res.data.data[i].supplyArea)
          that.option.series[1].data.push(res.data.data[i].firstSuTempature)
          that.option.series[2].data.push(res.data.data[i].totalArea)
          that.option.series[3].data.push(res.data.data[i].firstReTempature)
          that.option.series[4].data.push(res.data.data[i].firstSuPressure)
          that.option.xAxis.data.push(res.data.data[i].createDate)
          that.option.series[5].data.push(res.data.data[i].firstRePressure)
          that.option.series[6].data.push(res.data.data[i].secSuTempature)
          that.option.series[7].data.push(res.data.data[i].secReTempature)
          that.option.series[8].data.push(res.data.data[i].secSuPressure)
          that.option.series[9].data.push(res.data.data[i].secRePressure)
          that.option.series[10].data.push(res.data.data[i].targetTempature)
          that.option.series[11].data.push(res.data.data[i].secPressureMax)
          that.option.series[12].data.push(res.data.data[i].secPressureSetMax)
          that.option.series[13].data.push(res.data.data[i].secPressureSetMin)
          that.option.series[14].data.push(res.data.data[i].secPressureMin)
          that.option.series[15].data.push(res.data.data[i].mainValveSet)
          that.option.series[16].data.push(res.data.data[i].mainValveFeed)
          that.option.series[17].data.push(res.data.data[i].auxValveSet)
          that.option.series[18].data.push(res.data.data[i].auxValveFeed)
          that.option.series[19].data.push(res.data.data[i].waterTankLevel)
          that.option.series[20].data.push(res.data.data[i].waterTankMinLevel)
          that.option.series[21].data.push(res.data.data[i].circulatingPumpOn)
          that.option.series[22].data.push(res.data.data[i].circulatingPumpSet)
          that.option.series[23].data.push(res.data.data[i].circulatingPumpFeed)
          that.option.series[24].data.push(res.data.data[i].circulatingPumpCode)
          that.option.series[25].data.push(res.data.data[i].replenishingPumpOn)
          that.option.series[26].data.push(res.data.data[i].replenishingPumpSet)
          that.option.series[27].data.push(res.data.data[i].replenishingPumpFeed)
          that.option.series[28].data.push(res.data.data[i].replenishingPumpCode)
          that.option.series[29].data.push(res.data.data[i].firstFlowSet)
          that.option.series[30].data.push(res.data.data[i].firstConsumption)
          that.option.series[31].data.push(res.data.data[i].firstFlow)
          that.option.series[32].data.push(res.data.data[i].firstFlowSum)
          that.option.series[33].data.push(res.data.data[i].firstInstantHeat)
          that.option.series[34].data.push(res.data.data[i].firstHeatTotal)
          that.option.series[35].data.push(res.data.data[i].firstHeatPredict)
          that.option.series[36].data.push(res.data.data[i].replenishingFlow)
          that.option.series[37].data.push(res.data.data[i].replenishingTotal)
          that.option.series[38].data.push(res.data.data[i].secFlow)
          that.option.series[39].data.push(res.data.data[i].secFlowSum)
          that.option.series[40].data.push(res.data.data[i].electricPower)
          that.option.series[41].data.push(res.data.data[i].electricTotal)
          that.option.series[42].data.push(res.data.data[i].heatConsumption)
          that.option.series[43].data.push(res.data.data[i].firstFlowStart)
          that.option.series[44].data.push(res.data.data[i].firstHeatStart)
          that.option.series[45].data.push(res.data.data[i].replenishingStart)
          that.option.series[46].data.push(res.data.data[i].electricStart)
          that.option.series[47].data.push(res.data.data[i].roomTempatureAvg)
          that.option.series[48].data.push(res.data.data[i].leaking)
          that.option.series[49].data.push(res.data.data[i].debug)
        }
        var echarts = require('echarts');
        var myChart = echarts.init(document.getElementById('main'));
        myChart.setOption(that.option);
      }
    })
  }

}
</script>

<style scoped>

</style>
